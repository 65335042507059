<template>
  <div>
    <van-nav-bar title="盘点单" left-text="返回"  right-text="新增" left-arrow @click-left="back()" @click-right="onClickRight" />
  </div>
  <van-search v-model="value" placeholder="请输入搜索关键词" />
  <div class="d_form">
    <div class="all">
      <div class="table-container">
        <table class="wide-table-take">
          <tr>
            <th>序号</th>
            <th>编号</th>
            <th>日期</th>
            <th>仓库</th>
            <th>状态</th>
            <th>操作</th>
          </tr>
          <tr v-for="item in list" :key="item.num">
            <td>{{ item.num }}</td>
            <td>{{ item.code }}</td>
            <td>{{ item.time }}</td>
            <td>{{ item.warehouse }}</td>
            <td>{{ item.status }}</td>
            <td><van-button type="primary" size="mini" @click="goDetail(item.id)">编辑</van-button><van-button type="primary" size="mini">删除</van-button></td>
          </tr>
        </table>
      </div>
    </div>
  </div>
</template>
<script setup>
import { useRouter } from "vue-router"
import { Toast } from 'vant';
import { ref } from 'vue'
const router = useRouter()
const list = ref([
  {
    id: 1,
    num: 1,
    code: 'Take-05',
    mod: 'f01',
    status: '盘点中',
    time: '2024-09-09 10:00:00',
    warehouse: '仓库B'
  },
  {
    id: 2,
    num: 2,
    code: 'Take-05',
    mod: 'f01',
    status: '盘点中',
    time: '2024-09-09 10:00:00',
    warehouse: '仓库B'
  },
  {
    num: 3,
    id: 3,
    code: 'Take-05',
    mod: 'f01',
    status: '盘点中',
    time: '2024-09-09 10:00:00',
    warehouse: '仓库B'
  },
  {
    id: 4,
    num: 4,
    code: 'Take-05',
    mod: 'f01',
    status: '盘点中',
    time: '2024-09-09 10:00:00',
    warehouse: '仓库B'
  },
  {
    id: 5,
    num: 5,
    code: 'Take-05',
    mod: 'f01',
    status: '盘点中',
    time: '2024-09-09 10:00:00',
    warehouse: '仓库B'
  }
]);
const loading = ref(false);
const finished = ref(false);
const onLoad = () => {
};
function onClickRight() {
  router.push({ path: "/Take/add" })
}
function goDetail(id) {
  router.push({ path: `/take/edit/${id}` })
}
function back() {
  router.push({ path: "/index" })
}
</script>
<style scoped>
.flex{
  display: flex;
}
.scan{
  align-items: center;
}
.kan{
  justify-content: space-between;
  align-items: center;
}
</style>
<style>
.list2 .van-cell__title span{
  font-size: 0.3rem;
}
.fz {
  display: flex;
  width: 100%;
  flex-wrap: nowrap;
}
.fz div{
  width: 100px;
  display: flex;
  font-size: 0.3rem;
  margin-right: 10px;
}
.all{
  width: 100%;
  margin: 0 auto;
  background-color: #fff;
}
.flex{
  display: flex;
  width: 100%;
  overflow: scroll;
  justify-content: space-between;
}
</style>
